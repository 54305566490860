import styled from 'styled-components';

import { colors, h4Text, legacySpace } from 'theme/theme';
import { getStaticImageSrc } from 'utils/getImageSrc';

export const FindAJobDropdownBottomAdHolder = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  padding: 12px 24px;
  background-color: #f0faff;
  background-image: url(${getStaticImageSrc(
      'illustrations/hats-1-illustration.svg',
    )}),
    url(${getStaticImageSrc('illustrations/hats-2-illustration.svg')});
  background-position:
    0,
    100% top;
  background-repeat: no-repeat;
  background-size: 100px, 100px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  &:hover {
    cursor: pointer;
    background-color: #f1f1f1;
  }
`;

export const FindAJobDropdownBottomAdLeft = styled.div`
  margin-left: 100px;
`;

export const FindAJobDropdownBottomAdTag = styled.div`
  display: inline-block;
  font-size: 12px;
  color: white;
  background-color: #68bd53;
  border-radius: 6px;
  padding: 2px 6px;
  text-transform: uppercase;
`;

export const FindAJobDropdownBottomAdTitle = styled.h4`
  ${h4Text};
  margin-top: ${legacySpace[2]}px;
  margin-bottom: ${legacySpace[2]}px;
  line-height: 1;
`;

export const FindAJobDropdownBottomAdDetailList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    display: inline-block;
    margin-right: ${legacySpace[2]}px;
    padding-right: ${legacySpace[2]}px;
    border-right: 1px solid ${colors.selectionGrey};
  }

  li:last-child {
    margin-right: 0;
    padding-right: 0;
    border-right: none;
  }
`;

export const FindAJobDropdownBottomAdLink = styled.span`
  color: ${colors.brandBlue};
  font-size: 16px;
  font-weight: 600;
`;
