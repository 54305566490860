import { useCallback } from 'react';

import { Icon } from 'components/Icon/Icon';

import {
  FindAJobDropdownBottomAdDetailList,
  FindAJobDropdownBottomAdHolder,
  FindAJobDropdownBottomAdLeft,
  FindAJobDropdownBottomAdLink,
  FindAJobDropdownBottomAdTag,
  FindAJobDropdownBottomAdTitle,
} from './FindAJobDropdownBottomAd.styled';

export function FindAJobDropdownBottomGradfairAd() {
  const handleClick = useCallback(() => {
    window.location.href =
      'https://www.idealist.org/grad-schools/attend?utm_source=ad&utm_medium=header&utm_campaign=en_users_2024grf_register';
  }, []);

  return (
    <FindAJobDropdownBottomAdHolder onClick={handleClick}>
      <FindAJobDropdownBottomAdLeft>
        <FindAJobDropdownBottomAdTag>
          {getText('Free event')}
        </FindAJobDropdownBottomAdTag>
        <FindAJobDropdownBottomAdTitle>
          {getText('Idealist Social Impact Grad School Fair')}
        </FindAJobDropdownBottomAdTitle>
        <FindAJobDropdownBottomAdDetailList>
          <li>October 9, 2024, 5:00 PM – 8:00 PM ET</li>
          <li>New York City</li>
        </FindAJobDropdownBottomAdDetailList>
      </FindAJobDropdownBottomAdLeft>

      <FindAJobDropdownBottomAdLink>
        {getText('Learn more')} <Icon name="arrow-right" size={18} />
      </FindAJobDropdownBottomAdLink>
    </FindAJobDropdownBottomAdHolder>
  );
}
